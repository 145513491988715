.cardContainer{
  width: 100%;
  margin-top: 0;
}
.card {
  display: flex;
  padding: 10px 0 0 10px;
  flex-direction: column;
  background:#0B2C46;
  min-height: 50px;
  min-width: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  margin: 0 0 32px 0;
}
.cardEvidencesTecnical {
  display: flex;
  width: 100%;
  min-Height: 10vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 10px;
}
.leftIcon {
  width: 40%;
  display: flex;
  justify-content: center;
}
.cardEvidencesProfessional {
  display: flex;
  width: 100%;
  min-Height: 10vh;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  background-color: #0B2C46;
  border-radius: 10px;
}
.rigthButton {
  width: 60%;
  display: flex;
  margin-right: 10px;
  justify-content: center;
}

@media screen and (min-width: 998px) {
  .cardContainer{
    flex-direction: row;
    margin-top: 32px;
    grid-gap: 10px;
  }
  .card {
    flex-direction: row;
    min-height: 160px;
    display: flex;
    margin-top: 32px;
  }
}
