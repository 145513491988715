.boxContent {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 50px;
  min-width: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  margin: 0;
  padding: 40px;
}

@media screen and (min-width: 998px) {
  .boxContent {
    width: 100%;
    display: flex;
  }
}
